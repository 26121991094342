@tailwind base;
@tailwind components;
@tailwind utilities;

.booking {
    border: 1px solid #ccc;
    padding: 10px;
    width: 200px;
  }
  
  .booking-location {
    font-weight: bold;
  }
  
  .booking-date {
    margin-top: 5px;
  }
  
  .booking-time {
    margin-top: 5px;
  }
  
