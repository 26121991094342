.booking-cal {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Arial, sans-serif;
}

.booking-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 80vw;
  margin: 0 auto;
}

.slots {
  width: calc(100% / 6 - 1rem);
  margin-right: 1rem;
}

.slot {
  margin: 0.25rem 0.5rem;
}

.available {
  background-color: #9eff9a;
}

.selected {
  background-color: #9dd7ff;
}

.booked {
  background-color: #ff9a9a;
}

.slot button {
  height: 60px;
  width: calc(80vw / 10 - 20px);
  margin: 5px;
  border: none;
  border-radius: 10px;
  color: #fff;
  font-size: 16px;
  background-color: #26c470;
  transition: all 0.2s ease-in-out;
}

.slot button:hover {
  cursor: pointer;
  background-color: #2980b9;
}

.slot button.selected {
  background-color: #87cefa;
}

.slot button.booked {
  background-color: #ccc;
  cursor: not-allowed;
}

.booking-form {
  width: 100%;
  max-width: 400px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  margin-bottom: 20px;
  text-align: center;
  background-color: #f9f9f9;
}

.booking-form h2 {
  margin-top: 0;
  margin-bottom: 10px;
}

.booking-form p {
  margin-bottom: 10px;
}

.booking-form button {
  width: 100%;
  height: 30px;
  margin-top: 10px;
  border: none;
  border-radius: 5px;
  color: #fff;
  font-size: 14px;
  background-color: #3498db;
  transition: all 0.2s ease-in-out;
}

.booking-form button:hover {
  cursor: pointer;
  background-color: #2980b9;
}

@media only screen and (max-width: 600px) {
  .booking-list {
    flex-direction: column;
  }

  .slots {
    margin-bottom: 20px;
    width: 90%;
  }

  .booking-form {
    max-width: 100%;
  }

  .slot button {
    height: 50px;
    width: 100%;
    font-size: 1.5em;
  }
}
