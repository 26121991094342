.booking-list {
    display: flex;
    align-items: center;
  }
  
  .booking-section {
    margin: 20px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 50%;
  }
  
  .booking-section-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .booking {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 10px;
  }
  
  .booking-location {
    font-weight: bold;
  }
  
  .booking-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .booking-action-button {
    margin-left: 10px;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    color: #fff;
    background-color: #007bff;
    cursor: pointer;
  }
  
  .booking-action-button:hover {
    background-color: #0069d9;
  }
  
  .loading {
    font-size: 20px;
    font-weight: bold;
    margin: 20px;
  }
  
  .no-bookings {
    font-size: 20px;
    font-weight: bold;
    margin: 20px;
  }
  