.payment-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .apple-pay-button {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .apple-pay-button button {
    background-color: #000;
    color: #fff;
    font-size: 18px;
    padding: 12px 24px;
    border: none;
    border-radius: 4px;
  }
  