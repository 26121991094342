@tailwind base;
@tailwind components;
@tailwind utilities;

.location-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  .location-list input {
    width: 80%;
    padding: 10px;
    font-size: 18px;
    margin-bottom: 20px;
    border: 1px solid lightgray;
    border-radius: 5px;
    outline: none;
  }
  
  .location-grid {
    display: flex;
    flex-wrap: wrap;
    width: 80%;
    justify-content: center;
  }
  
  .location-tile {
    width: 30%;
    height: 150px;
    margin: 20px;
    text-align: center;
    background-color: lightgray;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    text-decoration: none;
    color: black;
  }
  
  .location-tile:hover {
    background-color: lightblue;
    color: white;
    cursor: pointer;
  }
  
  .location-name {
    padding: 40px 0;
  }
  