/* Header.css */

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2%;
    background-color: #eee;
  }
  
  .header img {
    height: 10vh;
  }
  
  /* Mobile styles */
  @media (max-width: 600px) {
    .header {
      flex-direction: column;
      align-items: center;
    }
  
    .header img {
      margin-bottom: 10px;
    }
  }
  
  .hamburger-menu {
    display: inline-block;
    cursor: pointer;
    margin-left: auto;
  }
  
  .hamburger-line {
    display: block;
    width: 30px;
    height: 3px;
    margin: 6px 0;
    background-color: black;
    transition: all 0.3s ease-in-out;
  }
  
  .hamburger-menu.open .hamburger-line:first-child {
    transform: rotate(45deg);
  }
  
  .hamburger-menu.open .hamburger-line:last-child {
    transform: rotate(-45deg);
  }
  
  .hamburger-menu.open .hamburger-line:nth-child(2) {
    opacity: 0;
  }
  